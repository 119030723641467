import React from "react"
import styled, { css } from "styled-components"
import { up, down } from "styled-breakpoints"
import colors from "@constants/colors"
import { columns14, columnsInMaxWidth } from "@constants/layout"
import SelectInput, {
  Container as SelectInputContainer,
} from "@components/inputs/SelectInput"
import { bodyBold } from "@constants/typography"
import _ from "lodash"
import { useRouter } from "next/router"
import {
  getCarsMakeModelYearUrl,
  getCarsMakeModelYearTrimUrl,
} from "@utils/urls"
import Link from "next/link"

const Container = styled.div<{
  bgColor: string
}>`
  ${({ bgColor }) => css`
    display: block;
    font-family: "Jost", sans-serif;
    background-color: ${bgColor ? bgColor : `#000000`};

    ${down("tablet")} {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 30px 0;
    }

    ${up("laptop")} {
      padding: 32px ${columns14(1)};
    }

    ${up("maxWidth")} {
      padding: 32px ${columnsInMaxWidth(1)};
    }
  `}

  button {
    background: ${colors.red};
    color: #fff;
    min-width: 150px;
    text-transform: uppercase;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.6s ease-out;

    ${down("tablet")} {
      width: 100%;
      padding: 15px;
    }

    &:hover {
      color: ${colors.red};
      background: #fff;
    }

    &:disabled {
      background: ${colors.mediumGray};
      cursor: not-allowed;
    }
  }
`

const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;

  ${up("laptop")} {
    flex-direction: row;
    padding: 0;
  }

  ${down("tablet")} {
    padding: 10px 40px;
  }
`

const Filters = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${up("laptop")} {
    flex-direction: row;
    padding: 0;
  }

  ${down("tablet")} {
    display: block;
    width: 100%;
  }
`

const Filter = styled.div<{ disabled?: boolean }>`
  min-width: 150px;
  font-family: "Jost", sans-serif;
  flex-grow: 0;
  margin-right: 20px;

  ${down("tablet")} {
    margin-right: 0;
    margin-bottom: 10px;
  }

  ${({ disabled }) => css`
    pointer-events: ${disabled ? "none" : "all"};

    [class*="SelectInput__Container"] {
      background-color: ${disabled ? colors.mediumGray : colors.lightestGray};
    }
  `}

  [class*="SelectInput__Container"] {
    padding: 0 8px;
    height: 36px;

    ${down("tablet")} {
      height: 50px;
    }
  }

  [class*="MuiInput-formControl"] {
    margin-top: 16px;
    height: 16px;
    font-size: 14px;

    ${down("tablet")} {
      margin-top: 22px;
    }
  }

  [class*="MuiInputLabel-root"] {
    font-family: "Jost", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 16px;
    transform: translate(0, 10px) scale(1);

    ${down("tablet")} {
      transform: translate(0, 18px) scale(1);
    }
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 4px) scale(0.6) !important;
  }

  .MuiNativeSelect-icon {
    top: 0px;
  }
`

const Label = styled.div<{
  fontColor: string
}>`
  ${({ fontColor }) => css`
    color: ${fontColor ? fontColor : `#ffffff`};
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;

    ${down("tablet")} {
      margin-bottom: 0;
      text-shadow: 0 0 10px black;
    }

    > div {
      font-size: 15px;
      font-weight: 200;

      ${down("tablet")} {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
      }
    }

    > h1 {
      font-size: 25px;
      line-height: 25px;
      font-weight: 400;
      margin: 0;

      ${down("tablet")} {
        display: none;
      }
    }
  `}
`

const SS = styled.div<{
  fontColor: string
}>`
  ${({ fontColor }) => css`
    color: ${fontColor ? fontColor : `#ffffff`};
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    display: flex;
    gap: 10px;

    ${down("tablet")} {
      margin-bottom: 0;
      text-shadow: 0 0 10px black;
      align-items: center;
      justify-content: center;
    }

    a {
      font-size: 15px;
      margin-right: 30px;

      &.active {
        border-bottom: 2px solid ${fontColor ? fontColor : `#ffffff`};
      }

      &:last-child {
        margin-right: 0px;
      }

      ${down("tablet")} {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        margin-right: 0px;
      }
    }
  `}
`

interface YMMProps {
  fontColor: string
  bgColor: string
  userModule?: string
}

const YmmSelector: React.FC<YMMProps> = ({
  fontColor,
  bgColor,
  userModule = "automotive",
}) => {
  const router = useRouter()
  const layoutData = require("@data/layoutData")
  const cars = layoutData.allCars.filter((car) => {
    return car.module == "all" || car.module == userModule
  })
  const [make, setMake] = React.useState<string | "">("")
  const [model, setModel] = React.useState<string | "">("")
  const [trim, setTrim] = React.useState<string | "">("")
  const [year, setYear] = React.useState<string | "">("")

  const setCar = (event) => {
    window.scrollTo({ top: 20, behavior: "smooth" })

    if (trim) {
      router.push(getCarsMakeModelYearTrimUrl(make, model, year, trim))
    } else {
      router.push(getCarsMakeModelYearUrl(make, model, year))
    }
  }

  const makes = _.sortBy(
    _.uniqBy(
      cars.map(({ make }) => ({ label: make.name, value: make.slug })).sort(),
      "value"
    ),
    ["value"]
  ) as Array<any>

  const [models, setModels] = React.useState<Array<any>>([])

  const [trims, setTrims] = React.useState<Array<any>>([])

  const [years, setYears] = React.useState<Array<any>>([])

  const onSelectMake = (make) => {
    const filteredModels = _.sortBy(
      _.uniqBy(
        cars
          .filter((car) => car.make.slug === make)
          .map(({ model }) => ({ label: model.name, value: model.slug })),
        "value"
      ),
      ["value"]
    )

    setMake(make)
    setModels(filteredModels)

    setMake(make)
    setModel("")
    setTrim("")
    setYear("")
    setTrims([])
    setYears([])

    document.getElementById("Model")?.focus()
  }

  const onSelectModel = (model) => {
    if (model.trim() != "") {
      const filteredCars = _.sortBy(
        cars.filter(
          (car) => car.make.slug === make && car.model.slug === model
        ),
        ["slug"]
      )

      if (filteredCars.length > 1) {
        const trims = _.sortBy(
          _.uniqBy(
            filteredCars.map(({ trim }) => ({
              label: trim.name,
              value: trim.slug,
            })) as any,
            "value"
          ),
          ["value"]
        ) as any

        setModel(model)
        setTrim("")
        setTrims(trims)
        setYears([])

        document.getElementById("Trim")?.focus()
      } else {
        const thisCar = filteredCars[0]
        const filteredYears = thisCar.years.split(", ").map((year) => ({
          label: year.trim(),
          value: year.trim(),
        }))

        setModel(model)
        setYear("")
        setTrim("")
        setTrims([])
        setYears(filteredYears)

        document.getElementById("Year")?.focus()
      }
    } else {
      setModel("")
      setTrims([])
      setYears([])
    }
  }

  const onSelectTrim = (trim) => {
    const thisCar = cars.find((car) => {
      return (
        car.make.slug === make &&
        car.model.slug === model &&
        car.trim.slug === trim
      )
    })

    if (thisCar) {
      const filteredYears = thisCar.years.split(", ").map((year) => ({
        label: year.trim(),
        value: year.trim(),
      }))

      setTrim(trim)
      setYears(filteredYears)
    }
  }

  return (
    <Container bgColor={bgColor}>
      <Label fontColor={fontColor}>
        <div>Shop by vehicle</div>
        <h1>Let's start that project</h1>
      </Label>
      <SS fontColor={fontColor}>
        <Link className={userModule == "automotive" ? "active" : ""} href="./">
          Automotive
        </Link>
        <Link
          className={userModule == "powersports" ? "active" : ""}
          href="./powersports/"
        >
          Powersports
        </Link>
      </SS>
      <Content>
        <Filters>
          <Filter>
            <SelectInput
              name="Make"
              label="Make"
              value={make}
              options={makes}
              onChange={(e) => {
                onSelectMake(e.target.value)
              }}
            />
          </Filter>
          <Filter disabled={!make}>
            <SelectInput
              name={"Model"}
              label={"Model"}
              value={model}
              options={models}
              onChange={(e) => {
                onSelectModel(e.target.value)
              }}
            />
          </Filter>
          {trims?.length > 0 && (
            <Filter disabled={!make || !model}>
              <SelectInput
                name={"Trim"}
                label={"Trim"}
                value={trim}
                options={trims}
                onChange={(e) => {
                  years
                  onSelectTrim(e.target.value)
                }}
              />
            </Filter>
          )}
          <Filter
            disabled={
              trims?.length > 0 ? !make || !model || !trim : !make || !model
            }
          >
            <SelectInput
              name={"Year"}
              label={"Year"}
              value={year}
              options={years}
              onChange={(e) => {
                setYear(e.target.value)
              }}
            />
          </Filter>
          <button
            disabled={make == "" || model == "" || year == ""}
            onClick={(e) => {
              setCar(e)
            }}
          >
            Build
          </button>
        </Filters>
      </Content>
    </Container>
  )
}

export default YmmSelector

import React from "react"
import { GraphQLUnionType } from "../types"
import ComponentCardSliderRecord from "./components/ComponentCardSlider"
import ComponentProductSliderRecord from "./components/ComponentProductSlider"
import ComponentCodeSnippetRecord from "./components/ComponentCodeSnippet"
import ComponentCtaBlockRecord from "./components/ComponentCtaBlock"
import ComponentDownloadRecord from "./components/ComponentDownload"
import ComponentFeatureListRecord from "./components/ComponentFeatureList"
import ComponentHeroRecord from "./components/ComponentHero"
import ComponentHeroCarouselRecord from "./components/ComponentHeroCarousel"
import ComponentImageRecord from "./components/ComponentImage"
import ComponentImageGridRecord from "./components/ComponentImageGrid"
import ComponentInfoBlockRecord from "./components/ComponentInfoBlock"
import ComponentTextBlockRecord from "./components/ComponentTextBlock"
import ComponentTextHeroRecord from "./components/ComponentTextHero"
import ComponentVerticalSpaceRecord from "./components/ComponentVerticalSpace"
import ComponentVideoRecord from "./components/ComponentVideo"
import ComponentYearSelectorRecord from "./components/ComponentYearSelector"
import ComponentYmmSelectorRecord from "./components/ComponentYmmSelector"
import ComponentPageHeaderRecord from "./components/ComponentPageHeader"

const components = {
  ComponentCardSliderRecord,
  ComponentProductSliderRecord,
  ComponentCodeSnippetRecord,
  ComponentCtaBlockRecord,
  ComponentDownloadRecord,
  ComponentFeatureListRecord,
  ComponentHeroRecord,
  ComponentHeroCarouselRecord,
  ComponentImageRecord,
  ComponentImageGridRecord,
  ComponentInfoBlockRecord,
  ComponentTextBlockRecord,
  ComponentTextHeroRecord,
  ComponentVerticalSpaceRecord,
  ComponentVideoRecord,
  ComponentYearSelectorRecord,
  ComponentYmmSelectorRecord,
  ComponentPageHeaderRecord,
}

const renderContent = (
  content: GraphQLUnionType | GraphQLUnionType[] | null
): React.ReactNode => {
  // console.log(content)

  // Preconditions
  if (content == null) {
    console.warn("renderContent, received undefined content", content)
    return null
  }

  if (Array.isArray(content)) {
    return content.map(renderContent)
  }

  // Render component
  const { __typename, id, ...rest } = content

  const Component = components[__typename]
  if (!Component) {
    console.warn("Component not found for", __typename, rest)
    return null
  }

  return (
    <Component
      renderContent={renderContent}
      module={module}
      key={id}
      id={id}
      {...rest}
    />
  )
}

interface ContentfulContentProps {
  content: any[]
  module?: string
}

const Component: React.FC<ContentfulContentProps> = ({
  content,
  module = "automotive",
}) => (
  <>
    {content
      ? content.map((c) => {
          c["userModule"] = module
          return renderContent(c)
        })
      : null}
  </>
)

export default Component

import React from "react"
import { DatoContentBaseProps } from "../../types"
import HeroCarousel from "@components/HeroCarousel"

const formatSlide = ({
  heroText,
  heroTextColor,
  heroTextSize,
  heroMobileTextSize,
  heroTextTopPadding,
  title,
  subtitle,
  textColor,
  textSize,
  mobileTextSize,
  url,
  buttonColor,
  buttonText,
  buttonTextColor,
  arrowBackgroundColor,
  backgroundImage,
  mobileBackgroundImage,
}) => ({
  heroText,
  heroTextColor,
  heroTextSize,
  heroMobileTextSize,
  heroTextTopPadding,
  title,
  subtitle,
  textColor,
  textSize,
  mobileTextSize,
  url,
  buttonColor,
  buttonText,
  buttonTextColor,
  arrowBackgroundColor,
  backgroundImage: backgroundImage?.responsiveImage,
  mobileBackgroundImage: mobileBackgroundImage?.responsiveImage,
})

interface DatoCmsComponentHeroCarouselProps extends DatoContentBaseProps {}

const DatoCmsComponentHeroCarousel: React.FC<
  DatoCmsComponentHeroCarouselProps
> = ({ slides, interval, userModule, ymmAttachment }) => {
  return (
    <HeroCarousel
      slides={slides.map(formatSlide)}
      interval={interval}
      userModule={userModule}
      ymmAttachment={ymmAttachment}
    />
  )
}

export default DatoCmsComponentHeroCarousel
